import { SET_RELATION, SET_RELATION_ACTIVITY, SET_RELATION_MOVEMENT } from './-mutation-types';
import { useRelationsStore } from '~/stores/relations';
import { type Relation } from '~/models/Relation';
import { type Activity } from '~/models/Activity';
import { type RelationMovement } from '~/models/RelationMovement';

const Mutations = {
    [SET_RELATION](payload: Relation | null) {
        const relationsStore = useRelationsStore();
        relationsStore.relation = payload;
    },
    [SET_RELATION_ACTIVITY](payload: Activity | null) {
        const relationsStore = useRelationsStore();
        relationsStore.activity = payload;
    },
    [SET_RELATION_MOVEMENT](payload: RelationMovement | null) {
        const relationsStore = useRelationsStore();
        relationsStore.movement = payload;
    },
};

export default Mutations;
