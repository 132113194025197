import { SET_RELATION, SET_RELATION_ACTIVITY, SET_RELATION_MOVEMENT } from './-mutation-types';
import { useRelationsStore } from '~/stores/relations';
import RelationService from '~/services/api/crm/RelationService';
import { type RelationMovement } from '~/models/RelationMovement';
import { type RelationActivity } from '~/models/RelationActivity';
import { type Relation } from '~/models/Relation';

const Actions = {
    async fetchRelation(relationId: number) {
        const relationsStore = useRelationsStore();
        const relationService = new RelationService();
        const response = await relationService.fetchRelation(relationId);

        relationsStore[SET_RELATION](response);
    },
    async fetchRelationActivity(payload: { activityId: number; relationId: number }) {
        const relationsStore = useRelationsStore();
        const { activityId, relationId } = payload;

        const relationService = new RelationService();

        const response = await relationService.fetchRelationActivity(relationId, activityId);

        relationsStore[SET_RELATION_ACTIVITY](response);
    },
    async fetchRelationMovement(payload: { movementId: number; relationId: number }) {
        const relationsStore = useRelationsStore();
        const { movementId, relationId } = payload;

        const relationService = new RelationService();

        const response = await relationService.fetchMovement(relationId, movementId);

        relationsStore[SET_RELATION_MOVEMENT](response);
    },
    setRelation(relation: Relation) {
        const relationsStore = useRelationsStore();
        relationsStore[SET_RELATION](relation);
    },
    setRelationActivity(relationActivity: RelationActivity) {
        const relationsStore = useRelationsStore();
        relationsStore[SET_RELATION_ACTIVITY](relationActivity);
    },
    setRelationMovement(movement: RelationMovement) {
        const relationsStore = useRelationsStore();
        relationsStore[SET_RELATION_MOVEMENT](movement);
    },
};

export default Actions;
