import { GET_RELATION, GET_RELATION_ACTIVITY, GET_RELATION_MOVEMENT } from './-getter-types';
import { useRelationsStore } from '~/stores/relations';
import { type Activity } from '~/models/Activity';
import { type Relation } from '~/models/Relation';
import { type RelationMovement } from '~/models/RelationMovement';

const Getters = {
    [GET_RELATION]: (): Relation | null => {
        const relationsStore = useRelationsStore();

        return relationsStore.relation;
    },
    [GET_RELATION_ACTIVITY]: (): Activity | null => {
        const relationsStore = useRelationsStore();

        return relationsStore.activity;
    },
    [GET_RELATION_MOVEMENT]: (): RelationMovement | null => {
        const relationsStore = useRelationsStore();

        return relationsStore.movement;
    },
};

export default Getters;
