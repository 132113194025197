import { type Activity } from '~/models/Activity';
import { type Relation } from '~/models/Relation';
import { type RelationMovement } from '~/models/RelationMovement';

export type RelationState = {
    relation: Relation | null;
    activity: Activity | null;
    movement: RelationMovement | null;
}

export const defaultStateData = (): RelationState => ({
    activity: null,
    movement: null,
    relation: null,
});

export const DefaultState = (): RelationState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
